<template>
  <div id="app">
    <div class="app-wrap">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>Language <br/>(not passed in request)</th>
          <th>Language Code <br/>(passed in request)</th>
          <th>Version <br/>(not passed in request)</th>
          <th>VersionIndex <br/>(this is what passed in request)</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="lang in languageList">
            <td v-for="index in 4">{{ lang[index-1] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { META } from '../assets/javascript/meta'

export default {
  components: {},
  mounted () {
    for (let lang in this.languages) {
      let language = this.ideMeta[this.languages[lang]]
      for (let ver in language.versions) {
        this.languageList.push([(ver > 0) ? '' : language.langDisplayName, (ver > 0) ? '' : this.languages[lang], language.versions[ver], ver])
      }
    }
  },
  data: function () {
    return {
      languages: ['java', 'c', 'c99', 'cpp', 'cpp14', 'cpp17', 'php', 'perl', 'python2', 'python3', 'ruby', 'go', 'scala',
        'bash', 'sql', 'pascal', 'csharp', 'vbn', 'haskell', 'objc', 'swift', 'groovy', 'fortran', 'brainfuck', 'lua', 'tcl', 'hack',
        'rust', 'd', 'ada', 'r', 'freebasic', 'verilog', 'cobol', 'dart', 'yabasic', 'clojure', 'nodejs', 'scheme', 'forth', 'prolog',
        'octave', 'coffeescript', 'icon', 'fsharp', 'nasm', 'gccasm', 'intercal', 'nemerle', 'ocaml', 'unlambda', 'picolisp', 'spidermonkey',
        'rhino', 'bc', 'clisp', 'elixir', 'factor', 'falcon', 'fantom', 'nim', 'pike', 'smalltalk', 'mozart', 'lolcode', 'racket', 'kotlin',
        'whitespace', 'erlang', 'jlang', 'haxe', 'fasm', 'awk', 'algol', 'befunge'],
      languageList: [],
      ideMeta: window._.cloneDeep(META)
    }
  }
}
</script>

<style lang="scss">
html {
  position: relative;
  min-height: 0;
  overflow-x: auto;
}

body {
  overflow-x: auto;
  background-color: white;
}

#app {

}

.app-wrap {
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>
